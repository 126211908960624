<template>
  <section class="interview-stack">
    <h2>{{ $t('interview.title') }}</h2>
    <div
      v-for="(card, index) in visibleCards"
      :key="index"
      :class="['card', { top: index === 0, middle: index === 1, bottom: index === 2 }]"
      :style="{ backgroundColor: card.color, color: card.fontColor }"
      @mouseover="tiltCard(index)"
      @mouseleave="resetTilt"
      @click="cycleCards"
    >
      <div v-if="card.key === 'cover'" class="cover-content">
        <h3>{{ card.title }}</h3>
        <p class="subtitle">{{ card.subtitle }}</p>
        <p>{{ card.interview }}</p>
        <img :src="card.image" alt="Cover Image" class="cover-image" />
        <img :src="card.image2" alt="Cover Image" class="cover-image2" />
        <div class="page-number">{{ card.page }}</div>
      </div>
      <div v-else>
        <h3 class="question-title" v-html="card.title"></h3>
        <p class="answer" v-html="card.answer"></p>
        <div class="page-number">{{ card.page }}</div>
      </div>
      <div class="arrow">➔</div>
    </div>
  </section>
</template>

<script setup>
import { ref, computed, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import coverImage from '@/assets/zeno.png';
import coverImage2 from '@/assets/card-sponsor.png';
const { t } = useI18n();

const rawCards = [
  {
    key: 'cover',
    color: '#013d4e',
    fontColor: '#ffffff',
    page: 1,
    image: coverImage,
    image2: coverImage2
  },
  {
    key: 'q1',
    color: '#0a925b',
    fontColor: '#ffffff',
    page: 2
  },
  {
    key: 'q2',
    color: '#e0747c',
    fontColor: '#ffffff',
    page: 3
  },
  {
    key: 'q3',
    color: '#509cd4',
    fontColor: '#ffffff',
    page: 4
  },
  {
    key: 'q4',
    color: '#ffffff',
    fontColor: '#002e41',
    page: 5
  }
];

const cards = ref([]);

watchEffect(() => {
  cards.value = rawCards.map(card => ({
    ...card,
    title: card.key === 'cover' ? t('interview.content.cover.title') : t(`interview.content.${card.key}.title`),
    subtitle: card.key === 'cover' ? t('interview.content.cover.subtitle') : '',
    interview: card.key === 'cover' ? t('interview.content.cover.interview') : '',
    answer: card.key !== 'cover' ? t(`interview.content.${card.key}.answer`) : ''
  }));
});

const visibleCards = computed(() => cards.value.slice(0, 3));

const cycleCards = () => {
  const firstCard = cards.value.shift();
  cards.value.push(firstCard);
};

const tiltCard = (index) => {
  if (index === 0) {
    document.querySelector('.card.top').style.transformOrigin = 'top left';
    document.querySelector('.card.top').style.transform = 'rotateX(-5deg) rotateY(-5deg)';
  }
};

const resetTilt = () => {
  document.querySelector('.card.top').style.transform = 'rotateX(0) rotateY(0)';
};
</script>

<style scoped>
.interview-stack {
  position: relative;
  width: 100%;
  max-width: 600px;
  height: 400px;
  margin: 0 auto;
  perspective: 1000px;
  margin-bottom: 10rem;
}

h2 {
  margin-bottom: 20px !important;
  font-size: 2rem !important;
  text-align: center !important;
  margin-bottom: 3rem !important;
}

.card {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, background-color 0.3s;
  cursor: pointer;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  overflow: hidden;
}

.cover-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.cover-content h3 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-top: -5rem;
  margin-bottom: -0.5rem;
}

.cover-content .subtitle {
  font-size: 1.5rem;
  font-style: italic;
  margin-bottom: -0.5rem;
}

.cover-content p {
  font-size: 1.2rem;
}

.cover-image {
  max-width: 25%;
  margin-bottom: 1rem;
  position: absolute;
  bottom: -1rem;
  right: 3rem;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.cover-image2 {
  max-width: 40%;
  margin-bottom: 1rem;
  position: absolute;
  bottom: -1rem;
  left: 0;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.page-number {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: bold;
  color: black;
}

.top {
  z-index: 3;
}

.middle {
  z-index: 2;
  transform: rotate(5deg) translateY(20px) translateX(10px);
  right: -52px;
}

.bottom {
  z-index: 1;
  transform: rotate(353deg) translateY(20px) translateX(10px);
  left: -52px;
}

.question-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
  font-weight: bold;
}

.answer {
  text-align: justify;
  margin-bottom: 20px; /* Add padding under the text */
}

.arrow {
  font-size: 2rem;
  position: absolute;
  top: 10px;
  right: 10px;
}

/* Mobile styles */
@media (max-width: 768px) {
  .interview-stack {
    width: 100%;
    height: auto;
  }

  .card {
    width: 90%;
    height: auto;
    margin: 0 auto;
    position: relative;
    transform: none !important;
    margin-bottom: 1rem;
  }

  .cover-content h3 {
    font-size: 2rem;
  }

  .cover-content .subtitle {
    font-size: 1.2rem;
  }

  .cover-content p {
    font-size: 1rem;
  }

  .cover-image {
    max-width: 50%;
    bottom: -1rem;
    right: 0rem;
    z-index: 1;
  }

  .cover-image2 {
    z-index: 0;
    max-width: 60%;
    bottom: -1rem;
    left: 0rem;
  }

  .page-number {
    width: 25px;
    height: 25px;
    font-size: 1rem;
    z-index: 3;
  }

  .top {
    z-index: 3;
    height: auto;
    min-height: 30rem;
  }

  .middle,
  .bottom {
    display: none;
  }

  .arrow {
    top: 1rem;
  }
}
</style>
