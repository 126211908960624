<template>
  <section id="gallery" class="gallery">
    <h2>{{ $t('gallery.title') }}</h2>

    <!-- LOADING PLACEHOLDER CARDS -->
    <div v-if="isLoading" class="loading-placeholder">
      <!-- Show as many placeholders as slidesPerPage indicates -->
      <div
        v-for="n in slidesPerPage"
        :key="n"
        class="placeholder"
      >
        <div class="placeholder-img"></div>
      </div>
    </div>

    <!-- ACTUAL GALLERY CAROUSEL -->
    <div class="carousel-wrapper" v-else>
      <button @click="prevSlide" class="carousel-control left">❮</button>
      <div class="carousel-container" ref="galleryCarousel">
        <div
          class="carousel-track-container"
          @touchstart="handleTouchStart"
          @touchend="handleTouchEnd"
        >
          <div
            class="carousel-track"
            :style="{ transform: `translateX(-${currentSlide * 100}%)` }"
          >
            <div
              v-for="(image, index) in images"
              :key="index"
              class="carousel-slide"
              @click="openModal(image)"
            >
              <div class="image-container">
                <div class="overlay-wrapper">
                  <img :src="image" alt="Choir Image" />
                  <a class="overlay-link">
                    <div class="overlay">
                      <span>{{ $t('gallery.content.button.postlink') }}</span>
                      <img
                        src="@/assets/finger_icon.svg"
                        alt="Finger Icon"
                        class="finger-icon"
                      />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button @click="nextSlide" class="carousel-control right">❯</button>
    </div>

    <!-- MODAL -->
    <div v-if="isModalOpen" class="modal" @click.self="closeModal">
      <div class="modal-content">
        <div class="image-wrapper">
          <button @click="closeModal" class="modal-close">&times;</button>
          <img :src="modalImage" alt="Modal Image" />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, onMounted, computed, onUnmounted } from 'vue';

const images = ref([]);
const currentSlide = ref(0);
const startX = ref(0);
const isModalOpen = ref(false);
const modalImage = ref('');

/** 1. NEW: loading flag for placeholders */
const isLoading = ref(true);

let interval = null;

/** 2. Fetch your images and, once done, set isLoading = false */
const loadGalleryImages = async () => {
  try {
    const response = await fetch('/.netlify/functions/fetchGalleryImages');
    const data = await response.json();
    if (data.photos) {
      images.value = data.photos;
    } else {
      console.error('Error fetching gallery images:', data.error);
    }
  } catch (error) {
    console.error('Error fetching gallery images:', error);
  } finally {
    isLoading.value = false;
  }
};

const slidesPerPage = computed(() => (window.innerWidth < 768 ? 1 : 3));
const totalSlides = computed(() => Math.ceil(images.value.length / slidesPerPage.value));

const prevSlide = () => {
  if (currentSlide.value > 0) {
    currentSlide.value--;
  } else {
    currentSlide.value = totalSlides.value - 1;
  }
  resetAutoRotate();
};

const nextSlide = () => {
  if (currentSlide.value < totalSlides.value - 1) {
    currentSlide.value++;
  } else {
    currentSlide.value = 0;
  }
  resetAutoRotate();
};

const handleTouchStart = (e) => {
  startX.value = e.touches[0].clientX;
};

const handleTouchEnd = (e) => {
  const endX = e.changedTouches[0].clientX;
  if (startX.value > endX + 50) {
    nextSlide();
  } else if (startX.value < endX - 50) {
    prevSlide();
  }
  resetAutoRotate();
};

const openModal = (image) => {
  // Show modal only on desktop
  if (window.innerWidth >= 768) {
    modalImage.value = image;
    isModalOpen.value = true;
  }
};

const closeModal = () => {
  isModalOpen.value = false;
  modalImage.value = '';
};

/** Auto-rotate logic, same as your original code */
const autoRotate = () => {
  interval = setInterval(nextSlide, 6000);
};

const resetAutoRotate = () => {
  clearInterval(interval);
  autoRotate();
};

window.addEventListener('resize', () => {
  currentSlide.value = 0;
  resetAutoRotate();
});

onMounted(() => {
  loadGalleryImages(); // fetch images
  autoRotate();        // start auto-rotation
});

onUnmounted(() => {
  clearInterval(interval);
});
</script>

<style scoped>
.gallery {
  padding: 50px 20px;
  background-color: rgba(248, 249, 250, 0); /* Semi-transparent background */
  text-align: center;
}

h2 {
  margin-bottom: 20px;
  font-size: 2rem;
}

.carousel-wrapper {
  position: relative;
  max-width: 80%;
  margin: 0 auto;
}

.carousel-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
}

.carousel-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  cursor: pointer;
  z-index: 10;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.carousel-control:hover {
  transform: translateY(-50%) scale(1.1);
}

.carousel-control:active {
  transform: translateY(-50%) scale(1);
}

.carousel-control.left {
  left: -20px;
}

.carousel-control.right {
  right: -20px;
}

.carousel-track-container {
  overflow: hidden;
  width: 100%;
}

.carousel-track {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.carousel-slide {
  flex: 0 0 33.33%;
  box-sizing: border-box;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  position: relative;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.image-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.overlay-wrapper {
  position: relative;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-container img {
  max-width: 100%;
  max-height: 40vh;
  object-fit: cover;
  border-radius: 10px;
}

.overlay-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border-radius: 10px;
  transition: opacity 0.3s ease, background-color 0.3s ease;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
}

.overlay-link:hover {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.5);
}

.overlay {
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.finger-icon {
  margin-top: 10px;
  width: 24px;
  height: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  width: auto;
  height: 80%;
  max-width: 1200px;
  margin: 0 auto;
}

.image-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.modal-content img {
  width: 100%;
  height: 100%; /* Adjust to fill available height */
  object-fit: contain; /* Maintain aspect ratio */
  border-radius: 10px;
}

.modal-close {
  position: absolute;
  top: -10px; /* Adjust as needed */
  right: -10px; /* Adjust as needed */
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  z-index: 10;
}

.modal-close:hover {
  transform: scale(1.1);
}

.modal-close:active {
  transform: scale(1);
}

/* RESPONSIVE: Single slide on narrow devices */
@media (max-width: 767px) {
  .carousel-slide {
    flex: 0 0 100%;
  }
  .image-container img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 40vh;
  }
  .overlay-link {
    display: none;
  }
}

/* ------------------------------------ */
/*  LOADING PLACEHOLDER STYLES (SAME!!) */
/* ------------------------------------ */
.loading-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem; /* or whatever gap you had in your facebook code */
}

.placeholder {
  width: 250px; /* adjust to match your real slides' typical width */
  min-height: 280px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.placeholder-img {
  width: 100%;
  height: 18rem; /* change if you want a different placeholder height */
  background: #e0e0e0;
  animation: placeholderShimmer 1.5s infinite linear;
  /* The same linear-gradient from your Facebook placeholders */
  background: linear-gradient(
    to right,
    #eeeeee 8%,
    #dddddd 18%,
    #eeeeee 33%
  );
  background-size: 800px 104px;
}

/* The shimmer effect */
@keyframes placeholderShimmer {
  0% {
    background-position: -400px 0;
  }
  100% {
    background-position: 400px 0;
  }
}
</style>
