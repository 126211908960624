<template>
  <section id="posts" class="facebook-posts">
    <h2>{{ $t('posts.title') }}</h2>
    
    <!-- LOADING PLACEHOLDER CARDS -->
    <div v-if="isLoading" class="loading-placeholder">
      <!-- Show as many placeholders as you want. 
           For example, we show 3 placeholders for desktop or 1 for mobile, 
           but you can also just hardcode a number (e.g., 3). -->

      <div
        v-for="n in slidesPerPage"
        :key="n"
        class="placeholder"
      >
        <div class="placeholder-img"></div>
      </div>
    </div>

    <!-- ACTUAL POSTS CAROUSEL -->
    <div class="carousel-wrapper" v-else>
      <button @click="prevSlide" class="carousel-control left">❮</button>
      <div
        class="carousel-container"
        @touchstart="handleTouchStart"
        @touchmove="handleTouchMove"
        @touchend="handleTouchEnd"
      >
        <div class="carousel-track-container" ref="carousel">
          <div
            class="carousel-track"
            :style="{ transform: `translateX(-${currentSlide * 100}%)`}"
          >
            <div
              v-for="(post, index) in posts"
              :key="index"
              class="carousel-slide"
            >
              <!-- EXACTLY YOUR EXISTING CODE HERE -->
              
              <!-- Case for full_picture and no video -->
              <div v-if="post.full_picture && !post.is_video">
                <img
                  :src="post.full_picture"
                  alt="Post Image"
                  class="post-img"
                  :class="{ 'full-slide': !post.message }"
                  @error="handleImageError($event)"
                />
                <a
                  :href="post.permalink_url"
                  target="_blank"
                  class="overlay-link"
                >
                  <div class="overlay">
                    <span>{{ $t('posts.content.button.postlink') }}</span>
                    <img
                      src="@/assets/finger_icon.svg"
                      alt="Finger Icon"
                      class="finger-icon"
                    />
                  </div>
                </a>
              </div>

              <!-- Case for YouTube video -->
              <div v-if="post.is_video && post.youtubeLink" class="post-video">
                <div class="video-container">
                  <iframe
                    :src="post.youtubeLink"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>

              <!-- Case for Facebook video -->
              <div v-else-if="post.is_video" class="post-video">
                <div
                  class="fb-video"
                  :data-href="`https://www.facebook.com/facebook/videos/${post.video_id}/`"
                  data-width="auto"
                  data-show-captions="false"
                ></div>
              </div>

              <!-- Case for text post without media -->
              <div v-if="post.message && !(post.is_video || post.is_vertical_reel)">
                <img
                  v-if="!post.full_picture && !post.is_video"
                  src="@/assets/singing.jpg"
                  alt="Default Image"
                  class="post-img"
                />
                <div class="post-content">
                  <div class="post-text-container">
                    <div class="post-text" v-html="post.message"></div>
                    <div class="fade"></div>
                  </div>
                </div>
                <a
                  :href="post.permalink_url"
                  target="_blank"
                  class="overlay-link"
                >
                  <div class="overlay">
                    <span>{{ $t('posts.content.button.postlink') }}</span>
                    <img
                      src="@/assets/finger_icon.svg"
                      alt="Finger Icon"
                      class="finger-icon"
                    />
                  </div>
                </a>
              </div>

              <!-- Case for a link-only post -->
              <div
                v-else-if="post.is_link && !post.message && !post.full_picture && !post.is_video"
              >
                <img
                  src="@/assets/link.jpg"
                  alt="Shared Link"
                  class="post-img"
                />
                <div class="post-content">
                  <div class="post-text-container">
                    <div class="post-text">{{ $t('posts.shared_link') }}</div>
                  </div>
                </div>
                <a
                  :href="post.permalink_url"
                  target="_blank"
                  class="overlay-link"
                >
                  <div class="overlay">
                    <span>{{ $t('posts.content.button.postlink') }}</span>
                    <img
                      src="@/assets/finger_icon.svg"
                      alt="Finger Icon"
                      class="finger-icon"
                    />
                  </div>
                </a>
              </div>
              
            </div>
          </div>
        </div>
      </div>
      <button @click="nextSlide" class="carousel-control right">❯</button>
    </div>
  </section>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';

const posts = ref([]);
const currentSlide = ref(0);
const startX = ref(0);
const deltaX = ref(0);

// NEW: loading flag
const isLoading = ref(true);

let interval = null;

// ~~~ same logic as you had before ~~~
const loadFacebookContent = async () => {
  try {
    const response = await fetch('/.netlify/functions/fetchFacebookPosts');
    const data = await response.json();

    if (data.posts) {
      posts.value = data.posts.map((post) => {
        if (post.is_video && post.attachments?.data?.length) {
          const attachment = post.attachments.data[0];
          if (attachment.media?.source?.includes('youtube.com')) {
            post.youtubeLink = attachment.media.source.replace('?autoplay=1', '');
          }
        }
        return post;
      });
    } else {
      console.error('Error fetching posts:', data.error);
    }
    
    // Once posts are loaded, stop showing placeholders
    isLoading.value = false;

    // Initialize Facebook SDK to render videos
    setTimeout(() => {
      window.FB && window.FB.XFBML.parse();
    }, 0);
  } catch (error) {
    console.error('Error fetching content:', error);
    // Even if error, remove loading placeholders to show some fallback or error message
    isLoading.value = false;
  }
};

const slidesPerPage = computed(() => (window.innerWidth < 768 ? 1 : 3));
const totalSlides = computed(() => Math.ceil(posts.value.length / slidesPerPage.value));

const prevSlide = () => {
  if (currentSlide.value > 0) {
    currentSlide.value--;
  } else {
    currentSlide.value = totalSlides.value - 1;
  }
  resetAutoRotate();
};

const nextSlide = () => {
  if (currentSlide.value < totalSlides.value - 1) {
    currentSlide.value++;
  } else {
    currentSlide.value = 0;
  }
  resetAutoRotate();
};

const handleTouchStart = (e) => {
  startX.value = e.touches[0].clientX;
};

const handleTouchMove = (e) => {
  deltaX.value = e.touches[0].clientX - startX.value;
};

const handleTouchEnd = () => {
  if (deltaX.value > 50) {
    prevSlide();
  } else if (deltaX.value < -50) {
    nextSlide();
  }
  resetAutoRotate();
};

const autoRotate = () => {
  interval = setInterval(nextSlide, 100000);
};

const resetAutoRotate = () => {
  clearInterval(interval);
  autoRotate();
};

const handleImageError = (event) => {
  event.target.src = require('@/assets/link.jpg'); // Set fallback image
};

window.addEventListener('resize', () => {
  currentSlide.value = 0; // Reset to first slide on resize to avoid layout issues
  resetAutoRotate();
});

onMounted(() => {
  loadFacebookContent();
  autoRotate();
});
</script>

<style scoped>
.facebook-posts {
  padding: 50px 20px;
  text-align: center;
}
h2 {
  margin-bottom: 20px;
  font-size: 2rem;
}

/* ---------------------- */
/* CAROUSEL STYLES        */
/* (same as your original) */
/* ---------------------- */

.carousel-wrapper {
  position: relative;
  max-width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 80%;
  overflow: hidden;
}

.carousel-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  cursor: pointer;
  z-index: 10;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.carousel-control:hover {
  transform: translateY(-50%) scale(1.1);
}

.carousel-control:active {
  transform: translateY(-50%) scale(1);
}

.carousel-control.left {
  left: -20px;
}

.carousel-control.right {
  right: -20px;
}

.carousel-track-container {
  overflow: hidden;
  width: 100%;
  padding: 0;
}

.carousel-track {
  display: flex;
  transition: transform 0.5s ease-in-out;
  align-items: center;
}

/* Each slide (post) */
.carousel-slide {
  flex: 0 0 calc(33.33% - 20px);
  height: 100%;
  box-sizing: border-box;
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.carousel-slide img {
  max-width: 100%;
  height: auto;
  border-radius: 10px 10px 0 0;
  z-index: 2;
  position: relative;
}

.post-img.full-slide {
  border-radius: 10px;
  width: auto;
  margin-bottom: -4px !important;
}

.post-video .fb-video {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 10px;
}

.post-content {
  background-color: white;
  padding: 10px;
  border-radius: 0 0 10px 10px;
  position: relative;
  z-index: 1;
  margin-top: -10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.post-text-container {
  max-height: 100px;
  overflow: hidden;
  position: relative;
}

.post-text {
  margin-bottom: 10px;
  text-align: left;
  position: relative;
  z-index: 1;
}

.fade {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 70px;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  ),
    linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.1)
    );
  z-index: 2;
}

.overlay-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.3s ease;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border-radius: 10px;
  opacity: 0;
  z-index: 5;
}

.overlay-link:hover {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.5);
}

.overlay {
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.finger-icon {
  margin-top: 10px;
  width: 24px;
  height: auto;
}

/* MEDIA QUERY for smaller screens */
@media (max-width: 767px) {
  .carousel-slide {
    flex: 0 0 100%;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 10px;
    box-shadow: none;
  }
  .post-img.full-slide {
    border-radius: 10px;
    width: 100%;
    height: auto;
    max-height: 300px;
    object-fit: cover;
    margin-bottom: 10px;
  }
  .post-content {
    font-size: 14px;
  }
  .post-text-container {
    max-height: 100px;
    overflow: hidden;
  }
  .fade {
    display: none;
  }
}

.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
  border-radius: 10px;
}
.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

/* -------------- */
/* LOADING STYLES */
/* -------------- */

.loading-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  /* If you want them side-by-side on desktop, 
     you could set a width limit and use a gap, etc. */
  gap: 3rem;
}

.placeholder {
  width: 250px; /* adjust to taste or to match your slides */
  min-height: 280px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.placeholder-img {
  width: 100%;
  height: 18rem;
  background: #e0e0e0;
  animation: placeholderShimmer 1.5s infinite linear;
}

.placeholder-text {
  width: 100%;
  height: 80px;
  background: #eee;
  animation: placeholderShimmer 1.5s infinite linear;
}

/* Simple “shimmer” effect */
@keyframes placeholderShimmer {
  0% {
    background-position: -400px 0;
  }
  100% {
    background-position: 400px 0;
  }
}
.placeholder-img,
.placeholder-text {
  background: linear-gradient(
    to right,
    #eeeeee 8%,
    #dddddd 18%,
    #eeeeee 33%
  );
  background-size: 800px 104px; /* can tweak size for effect speed/look */
}
</style>
